import React, { createContext, useState, useContext } from 'react';

const CommonContext = createContext();

export const CommonContextProvider = ({ children }) => {
    const [qrCodeBase64, setQrCodeBase64] = useState();
    const [companyDetails, SetCompanyDetails] = useState({ logo: '' });
    // const [invoiceDate, setInvoiceDate] = useState(format(new Date(), 'dd MMMM yyyy'));
    const [invoiceDate, setInvoiceDate] = useState(new Date());
    const [dashboardValues, setDashboardValues] = useState({
        TotalSales: '',
        ProductCount: '',
        TotalIncome: ''
    });
    return (
        <CommonContext.Provider
            value={{
                qrCodeBase64,
                setQrCodeBase64,
                companyDetails,
                SetCompanyDetails,
                invoiceDate,
                setInvoiceDate,
                dashboardValues,
                setDashboardValues
            }}
        >
            {children}
        </CommonContext.Provider>
    );
};

export const useCommonContextValues = () => {
    return useContext(CommonContext);
};
