const config = {
    // basename: only at build time to set, and Don't add '/' at end off BASENAME for breadcrumbs, also Don't put only '/' use blank('') instead,
    // like '/berry-material-react/react/default'
    basename: '/',
    defaultPath: '/dashboard',
    fontFamily: `'Inter', sans-serif`,
    borderRadius: 10
};

//if localstrg contains encryptlink redirect to login with link else normal /login
let ur_d;
if (localStorage.getItem('encryptLink')) {
    ur_d = '/login?ur_d=' + localStorage.getItem('encryptLink');
} else {
    ur_d = '/login';
}
export const loginPath = ur_d; // for login page redirect

// export const baseUrl = 'http://localhost:3000';
// export const linkUrl = 'http://localhost:3000/login';
export const baseUrl = 'https://integratedpos.wizzogroup.com/server';
export const linkUrl = 'https://integratedpos.wizzogroup.com/login';
// export const baseUrl = 'https://almaccloudpos.wizzosaudi.com/server';
// export const linkUrl = 'https://almaccloudpos.wizzosaudi.com/login';

export default config;
