import {
    IconCashBanknote,
    IconCoin,
    IconReportAnalytics,
    IconReportMoney,
    IconStorm,
    IconTruckReturn,
    IconFileAnalytics
} from '@tabler/icons';
// constant
const icons = {
    IconCashBanknote,
    IconCoin,
    IconReportAnalytics,
    IconReportMoney,
    IconStorm,
    IconTruckReturn,
    IconFileAnalytics
};

const reports = {
    id: 'reports',
    type: 'group',
    children: [
        {
            id: 'Reports',
            title: 'Manage Reports',
            type: 'collapse',
            icon: icons.IconReportAnalytics,
            children: [
                // {
                //     id: 'GST-Reports',
                //     title: 'GST Reports',
                //     type: 'item',
                //     url: '/reports/gst',
                //     icon: icons.IconCoin,
                //     breadcrumbs: false
                // },
                {
                    id: 'VAT-Reports',
                    title: 'VAT Reports',
                    type: 'item',
                    url: '/reports/vat',
                    icon: icons.IconCashBanknote,
                    breadcrumbs: false
                },
                {
                    id: 'Sales-Report',
                    title: 'Sales Report',
                    type: 'item',
                    url: '/reports/SalesReport',
                    icon: icons.IconReportMoney,
                    breadcrumbs: false
                },
                {
                    id: 'SalesReturn-Report',
                    title: 'SalesReturn Report',
                    type: 'item',
                    url: '/reports/SalesReturnReport',
                    icon: icons.IconFileAnalytics,
                    breadcrumbs: false
                },
                {
                    id: 'Purchase-Report',
                    title: 'Purchase Report',
                    type: 'item',
                    url: '/reports/PurchaseReport',
                    icon: icons.IconFileAnalytics,
                    breadcrumbs: false
                },
                {
                    id: 'Stock-Report',
                    title: 'Stock Report',
                    type: 'item',
                    url: '/reports/Stock',
                    icon: icons.IconTruckReturn,
                    breadcrumbs: false
                }
            ]
        }
    ]
};
export default reports;
