import { lazy } from 'react';
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
const Index = Loadable(lazy(() => import('views/Settings/index')));

const Settings = {
    path: '/',
    element: <MainLayout />,
    children: [
        {
            path: 'settings',
            element: <Index />
        }
    ]
};

export default Settings;
