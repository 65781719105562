import React, { useRef, useEffect, useState } from 'react';
import { Box, Divider, Container, Grid } from '@mui/material';
import { useReactToPrint } from 'react-to-print';
import { useNavigate, useLocation } from 'react-router';
import axios from 'axios';
import { baseUrl, loginPath } from 'config';
import dayjs from 'dayjs';

const BatchPrint = () => {
    const location = useLocation();
    const { data, form } = location.state;
    const componentRef = useRef();
    const [company, setCompany] = useState({});
    const navigate = useNavigate();
    console.log(data, form);
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
        onAfterPrint: () => {
            axios({
                method: 'post',
                url: baseUrl + '/logout'
            })
                .then((res) => {
                    navigate(loginPath);
                })
                .catch((err) => {
                    if (err.response.status === 403) navigate(loginPath);
                });
        }
    });
    useEffect(() => {
        axios.get(baseUrl + '/getCompanyDetails').then((res) => {
            setCompany(res.data[0]);
        });
        handlePrint();
    }, []);
    useEffect(() => {
        handlePrint();
    }, [company]);

    return (
        <div>
            <a href="#" onClick={handlePrint}>
                Print this out!
            </a>
            <Box width={380} ref={componentRef} sx={{ background: 'white', p: 1, pt: 3, color: 'black' }}>
                {/* Header section */}
                <Container disableGutters sx={{ display: 'flex' }}>
                    <small>GSTIN:</small>
                    <small>1234567890</small>
                </Container>
                <Container
                    className="vt_print_ther_head"
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        flexDirection: 'column',
                        p: 1.5
                    }}
                >
                    <h2 style={{ margin: 0 }}>{company.Name}</h2>
                    <p style={{ margin: 0 }}>{company.Add1} </p>
                    <p style={{ margin: 0 }}>{company.Ph} </p>
                </Container>
                <Container disableGutters>
                    <Divider />
                    <Divider />
                    <Grid container spacing={1} sx={{ pt: 1 }}>
                        <Grid item xs={4}>
                            Counter No
                        </Grid>
                        <Grid item xs={8}>
                            : 654
                        </Grid>
                        <Grid item xs={4}>
                            Salesman
                        </Grid>
                        <Grid item xs={8}>
                            : {data.salesman}
                        </Grid>
                        <Grid item xs={4}>
                            Opening Date
                        </Grid>
                        <Grid item xs={8}>
                            : {dayjs(data.opening.LoginDate).format('DD/MM/YYYY')}
                        </Grid>
                        <Grid item xs={4}>
                            Closing Date
                        </Grid>
                        <Grid item xs={8}>
                            : {dayjs(data.opening.LogoutDate).format('DD/MM/YYYY')}
                        </Grid>
                        <Grid item xs={12}>
                            <Divider />
                            <Divider />
                        </Grid>
                        <Grid item xs={6}>
                            Opening Cash
                        </Grid>
                        <Grid item xs={6}>
                            : {data.opening.OpCash}
                        </Grid>
                        <Grid item xs={6}>
                            Running Cash
                        </Grid>
                        <Grid item xs={6}>
                            : {data.sales_total - data.bank_sale}
                        </Grid>
                        <Grid item xs={6}>
                            Sales Return
                        </Grid>
                        <Grid item xs={6}>
                            : {data.sales_ret_tot}
                        </Grid>
                        <Grid item xs={6}>
                            Cash Reciept
                        </Grid>
                        <Grid item xs={6}>
                            : {data.rec_val}
                        </Grid>
                        <Grid item xs={6}>
                            Closing Cash Balance
                        </Grid>
                        <Grid item xs={6}>
                            : {data.opening.OpCash + data.sales_total + data.rec_val - data.sales_ret_tot}
                        </Grid>
                        <Grid item xs={6}>
                            Bank Sale
                        </Grid>
                        <Grid item xs={6}>
                            : {data.bank_sale}
                        </Grid>
                        <Grid item xs={6}>
                            Bank Reciept
                        </Grid>
                        <Grid item xs={6}>
                            : {data.bank_sale}
                        </Grid>
                        <Grid item xs={6}>
                            Total Bank Amount
                        </Grid>
                        <Grid item xs={6}>
                            : {data.bank_sale - data.cred_sale}
                        </Grid>
                        <Grid item xs={6}>
                            Credit Sale
                        </Grid>
                        <Grid item xs={6}>
                            : {data.cred_sale}
                        </Grid>
                        <Grid item xs={6}>
                            Total Discount
                        </Grid>
                        <Grid item xs={6}>
                            : {data.discount}
                        </Grid>
                        <Grid item xs={12}>
                            <hr style={{ borderTop: 'dashed 1px #8c8b8b', margin: 0 }} />
                            <hr style={{ borderTop: 'dashed 1px #8c8b8b', margin: 0 }} />
                        </Grid>
                        <Grid item xs={6} sx={{ fontWeight: 600 }}>
                            Net Sale
                        </Grid>
                        <Grid item xs={6}>
                            : {data.sales_total}
                        </Grid>
                        {/* <Grid item xs={6}>
                            Exclude Vat
                        </Grid>
                        <Grid item xs={6}>
                            : 0
                        </Grid>
                        <Grid item xs={6}>
                            Vat 15%
                        </Grid>
                        <Grid item xs={6}>
                            : 0
                        </Grid> */}
                        <Grid item xs={12}>
                            <Divider />
                            <Divider />
                        </Grid>
                        <Grid item xs={6}>
                            Total Cash
                        </Grid>
                        <Grid item xs={6}>
                            : {data.sales_total - data.bank_sale - data.cred_sale - data.sales_ret_tot + data.rec_val}
                        </Grid>
                        <Grid item xs={6}>
                            Total Bank
                        </Grid>
                        <Grid item xs={6}>
                            : {data.bank_sale}
                        </Grid>
                        <Grid item xs={6}>
                            Total
                        </Grid>
                        <Grid item xs={6}>
                            : {data.sales_total + data.bank_sale - data.bank_sale - data.sales_ret_tot - data.cred_sale + data.rec_val}
                        </Grid>
                        <Grid item xs={12}>
                            <Divider />
                            <Divider />
                        </Grid>
                        <Grid item xs={6}>
                            Actual Cash
                        </Grid>
                        <Grid item xs={6}>
                            : {form.actual_cash}
                        </Grid>
                        <Grid item xs={6}>
                            Actual Bank
                        </Grid>
                        <Grid item xs={6}>
                            : {form.actual_bank}
                        </Grid>
                        <Grid item xs={12}>
                            <hr style={{ borderTop: 'dashed 1px #8c8b8b', margin: 0 }} />
                            <hr style={{ borderTop: 'dashed 1px #8c8b8b', margin: 0 }} />
                        </Grid>
                    </Grid>
                </Container>
            </Box>
        </div>
    );
};

export default BatchPrint;
