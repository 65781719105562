import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Box, Typography } from '@mui/material';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { addItemToTableData } from 'views/Sales-Sections/salesReturn/slice/tableDataSlice';
import { useState } from 'react';
import dayjs from 'dayjs';
import BatchWindowPos from './BatchWindow';

function MultiUnitWindowPos({ product, modalClose, batchWise, expiry }) {
    const dispatch = useDispatch();
    const [openBatch, setOpenBatch] = useState(false);
    const [updatedItem, setUpdatedItem] = useState(null);

    const handleRowClick = (item) => {
        const updatedProduct = {
            ...product,
            Rate1: item.rate,
            Cost: item.cost,
            Pack: item.pack,
            qty: 1,
            Typ: item.typ,
            exp_date: dayjs().format('DD/MM/YYYY')
        };

        // Calculate the tax amount after the product.Tax1 variable has been initialized.
        updatedProduct.taxAmt = ((1 * updatedProduct.Rate1) / 100) * product.Tax1;
        updatedProduct.total = (updatedProduct.Rate1 + updatedProduct.taxAmt) * updatedProduct.qty;
        console.log(updatedProduct.Rate1 + updatedProduct.taxAmt);

        if (batchWise === 'YES BATCH') {
            setUpdatedItem(updatedProduct);
            setOpenBatch(true);
        } else {
            dispatch(addItemToTableData(updatedProduct));
            modalClose();
        }
    };

    return (
        <>
            {!openBatch ? (
                <>
                    {' '}
                    <Box sx={{ width: 380, maxWidth: '100%' }}>
                        <Box sx={{ py: 1, textAlign: 'center' }}>
                            <Typography variant="h3">Units</Typography>
                        </Box>
                        <TableContainer>
                            <Table aria-label="simple table">
                                <TableHead sx={{ background: '#2196F3', color: '#fff' }}>
                                    <TableRow>
                                        <TableCell sx={{ color: 'white' }} align="center">
                                            Unit
                                        </TableCell>
                                        <TableCell align="center" sx={{ color: 'white' }}>
                                            Cost
                                        </TableCell>
                                        <TableCell align="center" sx={{ color: 'white' }}>
                                            Rate
                                        </TableCell>
                                        <TableCell align="center" sx={{ color: 'white' }}>
                                            Pack
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        onClick={() =>
                                            handleRowClick({ typ: product.Typ, cost: product.Cost, rate: product.Rate1, pack: 1 })
                                        }
                                    >
                                        <TableCell component="th" scope="row" align="center">
                                            {product.Typ}
                                        </TableCell>
                                        <TableCell align="center"> {product.Cost}</TableCell>
                                        <TableCell align="center"> {product.Rate1}</TableCell>
                                        <TableCell align="center">1</TableCell>
                                    </TableRow>

                                    <TableRow
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        onClick={() =>
                                            handleRowClick({
                                                typ: product.SecUnit,
                                                cost: product.SecCostRate,
                                                rate: product.SecRetailRate,
                                                pack: product.Pack
                                            })
                                        }
                                    >
                                        <TableCell component="th" scope="row" align="center">
                                            {product.SecUnit}
                                        </TableCell>
                                        <TableCell align="center"> {product.SecCostRate}</TableCell>
                                        <TableCell align="center"> {product.SecRetailRate}</TableCell>
                                        <TableCell align="center"> {product.Pack}</TableCell>
                                    </TableRow>

                                    <TableRow
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        onClick={() =>
                                            handleRowClick({
                                                typ: product.ThirdUnit,
                                                cost: product.ThirdCost,
                                                rate: product.ThirdRate1,
                                                pack: product.ThirdPack
                                            })
                                        }
                                    >
                                        <TableCell component="th" scope="row" align="center">
                                            {product.ThirdUnit}
                                        </TableCell>
                                        <TableCell align="center"> {product.ThirdCost}</TableCell>
                                        <TableCell align="center"> {product.ThirdRate1}</TableCell>
                                        <TableCell align="center"> {product.ThirdPack}</TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Box>
                </>
            ) : (
                <BatchWindowPos product={updatedItem} modalClose={modalClose} expiry={expiry} />
            )}
        </>
    );
}

MultiUnitWindowPos.propTypes = {
    product: PropTypes.object.isRequired,
    modalClose: PropTypes.func.isRequired,
    batchWise: PropTypes.string.isRequired,
    expiry: PropTypes.string.isRequired
};

export default MultiUnitWindowPos;
