import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';

// dashboard routing

// utilities routing
const SubCategory = Loadable(lazy(() => import('views/others/SubCategory')));
const UserPrivilege = Loadable(lazy(() => import('views/others/UserPrivilege')));
const ThermalPrint = Loadable(lazy(() => import('views/others/ThermalPrint')));
const A4Print = Loadable(lazy(() => import('views/others/A4Print')));
// const VatReports = Loadable(lazy(() => import('views/reports/VatReports')));
// const SalesReport = Loadable(lazy(() => import('views/reports/SalesReport')));
// const SalesReturnReport = Loadable(lazy(() => import('views/reports/SalesReturnReport')));
// const PurchaseReport = Loadable(lazy(() => import('views/reports/PurchaseReport')));
// const StockReport = Loadable(lazy(() => import('views/reports/StockReport')));
// // sample page routing
// const SamplePage = Loadable(lazy(() => import('views/sample-page')));

const Others = {
    path: '/',
    element: <MainLayout />,
    children: [
        {
            path: 'others',
            children: [
                {
                    path: 'createcategory',
                    element: <SubCategory />
                },
                {
                    path: 'userPrivilege',
                    element: <UserPrivilege />
                },
                {
                    path: 'thermalPrint',
                    element: <ThermalPrint />
                },
                {
                    path: 'a4',
                    element: <A4Print />
                }
            ]
        }
    ]
};

export default Others;
