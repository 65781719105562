import { createSlice } from '@reduxjs/toolkit';

const tableDataPosSlice = createSlice({
    name: 'tableDataPos',
    initialState: [],
    reducers: {
        addItemToTableData: (state, action) => {
            state.push(action.payload);
        },
        updateQtyAndTotal: (state, action) => {
            const { index, qty, total, taxAmt } = action.payload;
            state[index].qty = qty;
            state[index].total = total;
            state[index].taxAmt = taxAmt;
        },

        updateRateAndTotal: (state, action) => {
            const { index, Rate1, qty, taxAmt } = action.payload;
            state[index].Rate1 = Rate1;
            state[index].total = Rate1 * qty + taxAmt;
            state[index].taxAmt = taxAmt;
        },
        deleteItemFromTableData: (state, action) => {
            const index = state.findIndex((item) => item.ItemNo === action.payload.ItemNo);
            if (index !== -1) {
                state.splice(index, 1);
            }
        },
        clearTableData: (state, action) => {
            return [];
        },
        // New reducer to replace the array with new data
        replaceTableData: (state, action) => {
            return action.payload; // Set the state to the new data
        }
    }
});

export const { addItemToTableData, updateRateAndTotal, updateQtyAndTotal, deleteItemFromTableData, clearTableData, replaceTableData } =
    tableDataPosSlice.actions;
export default tableDataPosSlice.reducer;
