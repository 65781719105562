import { useSelector, useDispatch } from 'react-redux';
import { useRef, useEffect } from 'react';

import { updateRateAndTotal, updateQtyAndTotal, deleteItemFromTableData } from 'views/Sales-Sections/salesReturn/slice/tableDataSlice';
import { TableContainer, Table, TableBody, TableHead, TableCell, TableRow, IconButton, Box, Paper, TextField } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { useTheme } from '@mui/material/styles';
import AddItemFileds from './AddItemFileds';

const styles = {
    tableContainer: {
        height: '45vh',
        overflow: 'hidden',
        marginTop: 1,
        background: '#fff'
    },
    tableBody: {
        maxHeight: '300px',
        overflowY: 'auto'
    }
};
const TableSection = () => {
    const tableRef = useRef(null);
    const theme = useTheme();
    const dispatch = useDispatch();
    const tableData = useSelector((state) => state.tableData);

    useEffect(() => {
        // Scroll to the bottom of the table body when new rows are added
        setTimeout(() => {
            if (tableRef.current) {
                tableRef.current.lastChild.scrollIntoView({ behavior: 'smooth' });
            }
        }, 0);
    }, [tableData]);
    // Update quantity value
    const handleQtyChange = (index, newValue) => {
        const qty = parseInt(newValue);
        // Calculate the new total based on the updated qty
        const newItem = {
            ...tableData[index],
            qty: qty,
            taxAmt: ((qty * tableData[index].Rate1) / 100) * tableData[index].Tax1,
            total: qty * tableData[index].Rate1 + ((qty * tableData[index].Rate1) / 100) * tableData[index].Tax1
        };
        // Dispatch the action to update qty and total
        dispatch(updateQtyAndTotal({ index, qty: newItem.qty, total: newItem.total, taxAmt: newItem.taxAmt }));
    };

    // Update rate value
    const handleRateChange = (index, newValue) => {
        // Calculate the new total based on the updated Rate1
        const item = tableData[index];
        const newRate1 = newValue;
        const tax = ((item.qty * newRate1) / 100) * item.Tax1;
        // const newTotal = newRate1 * item.qty;

        // Dispatch the action to update Rate1 and total
        dispatch(updateRateAndTotal({ index, Rate1: newRate1, qty: item.qty, taxAmt: tax }));
    };
    // Delete product from table
    const deleteItemFromTable = (itemNo) => {
        dispatch(deleteItemFromTableData({ ItemNo: itemNo }));
    };

    return (
        <>
            <Box sx={{ px: 1, pt: 0.5 }}>
                <AddItemFileds />
            </Box>

            <Box
                sx={{ background: '#fff', height: '45vh', overflow: 'hidden', mt: 1 }}
                className="productTable"
                component={Paper}
                elevation={3}
            >
                <TableContainer sx={{ height: '100%' }}>
                    <Table stickyHeader size="small" aria-label="a dense table" sx={{ textAlign: 'center' }}>
                        <TableHead>
                            <TableRow>
                                <TableCell sx={{ textAlign: 'center', background: `${theme.palette.grey[500]}`, color: '#fff' }}>
                                    Action
                                </TableCell>
                                <TableCell sx={{ textAlign: 'center', background: `${theme.palette.grey[500]}`, color: '#fff' }}>
                                    SiNo
                                </TableCell>
                                <TableCell sx={{ textAlign: 'center', background: `${theme.palette.grey[500]}`, color: '#fff' }}>
                                    Barcode
                                </TableCell>
                                <TableCell
                                    sx={{
                                        textAlign: 'center',
                                        background: `${theme.palette.grey[500]}`,
                                        color: '#fff',
                                        width: '200px',
                                        overflow: 'hidden'
                                    }}
                                >
                                    Product Name
                                </TableCell>
                                <TableCell
                                    sx={{
                                        textAlign: 'center',
                                        background: `${theme.palette.grey[500]}`,
                                        color: '#fff',
                                        minWidth: '100px',
                                        maxWidth: '100px'
                                    }}
                                >
                                    Qty
                                </TableCell>
                                <TableCell
                                    sx={{
                                        textAlign: 'center',
                                        background: `${theme.palette.grey[500]}`,
                                        color: '#fff',
                                        minWidth: '120px',
                                        maxWidth: '120px'
                                    }}
                                >
                                    Rate
                                </TableCell>
                                <TableCell sx={{ textAlign: 'center', background: `${theme.palette.grey[500]}`, color: '#fff' }}>
                                    Tax %
                                </TableCell>
                                <TableCell sx={{ textAlign: 'center', background: `${theme.palette.grey[500]}`, color: '#fff' }}>
                                    Tax ₹{' '}
                                </TableCell>
                                <TableCell sx={{ textAlign: 'center', background: `${theme.palette.grey[500]}`, color: '#fff' }}>
                                    Total
                                </TableCell>
                                <TableCell sx={{ textAlign: 'center', background: `${theme.palette.grey[500]}`, color: '#fff' }}>
                                    Cost
                                </TableCell>
                                <TableCell sx={{ textAlign: 'center', background: `${theme.palette.grey[500]}`, color: '#fff' }}>
                                    Pack
                                </TableCell>
                                <TableCell sx={{ textAlign: 'center', background: `${theme.palette.grey[500]}`, color: '#fff' }}>
                                    Unit
                                </TableCell>
                                <TableCell sx={{ textAlign: 'center', background: `${theme.palette.grey[500]}`, color: '#fff' }}>
                                    Batch
                                </TableCell>
                                <TableCell sx={{ textAlign: 'center', background: `${theme.palette.grey[500]}`, color: '#fff' }}>
                                    Exp Date
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody style={styles.tableBody}>
                            {tableData.map((item, index) => {
                                return (
                                    <TableRow ref={tableRef} sx={{ textAlign: 'center' }} key={index}>
                                        <TableCell align="center">
                                            <IconButton onClick={() => deleteItemFromTable(item.ItemNo)}>
                                                <DeleteIcon />
                                            </IconButton>
                                        </TableCell>
                                        <TableCell align="center">{index + 1}</TableCell>
                                        <TableCell
                                            align="center"
                                            sx={{
                                                minWidth: '50px',
                                                maxWidth: '50px',
                                                whiteSpace: 'nowrap',
                                                overflow: 'hidden',
                                                textOverflow: 'ellipsis'
                                            }}
                                        >
                                            {item.ItemC}
                                        </TableCell>
                                        <TableCell
                                            align="center"
                                            sx={{
                                                minWidth: '140px',
                                                maxWidth: '210px',
                                                whiteSpace: 'nowrap',
                                                overflow: 'hidden',
                                                textOverflow: 'ellipsis'
                                            }}
                                        >
                                            {item.ItemNameTextField}
                                        </TableCell>
                                        <TableCell
                                            align="center"
                                            sx={{
                                                minWidth: '100px',
                                                maxWidth: '100px',
                                                whiteSpace: 'nowrap',
                                                overflow: 'hidden',
                                                textOverflow: 'ellipsis'
                                            }}
                                        >
                                            <TextField
                                                size="small"
                                                type="number"
                                                value={item.qty}
                                                onChange={(e) => handleQtyChange(index, e.target.value)}
                                            />
                                        </TableCell>
                                        <TableCell
                                            align="center"
                                            sx={{
                                                minWidth: '120px',
                                                maxWidth: '120px',
                                                whiteSpace: 'nowrap',
                                                overflow: 'hidden',
                                                textOverflow: 'ellipsis'
                                            }}
                                        >
                                            <TextField
                                                size="small"
                                                type="number"
                                                value={item.Rate1}
                                                onChange={(e) => handleRateChange(index, e.target.value)}
                                            />
                                        </TableCell>
                                        <TableCell align="center">{item.Tax1}</TableCell>
                                        <TableCell align="center">{item.taxAmt.toFixed(2)}</TableCell>
                                        <TableCell align="center">{item.total.toFixed(3)}</TableCell>
                                        <TableCell align="center">{item.Cost}</TableCell>
                                        <TableCell align="center">{item.Pack}</TableCell>
                                        <TableCell align="center">{item.Typ}</TableCell>
                                        <TableCell align="center">{item.batch}</TableCell>
                                        {/* <TableCell align="center">{item.exp_date}</TableCell> */}
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
        </>
    );
};

export default TableSection;
