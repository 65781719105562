import { lazy } from 'react';
import SalesLayOut from 'views/Sales-Sections/SalesLayOut';
import Loadable from 'ui-component/Loadable';
import SalesReturn from 'views/Sales-Sections/salesReturn/SalesReturn';
import SalesPos from 'views/Sales-Sections/salesPos/SalesPos';

// const SalesReturn = Loadable(lazy(() => import('views/Sales-Sections/salesReturn/SalesReturn')));
// const SalesPos = Loadable(lazy(() => import('views/Sales-Sections/salesPos/SalesPos')));

const SalesRoute = {
    path: 'sales',
    element: <SalesLayOut />,
    children: [
        {
            path: 'salesPos',
            element: <SalesPos typp="salesBill" />
        },
        {
            path: 'salesReturn',
            element: <SalesReturn typp="salesReturn" />
        }

        // {
        //     path: 'print',
        //     element: <Print />
        // }
    ]
};
export default SalesRoute;
