import { useSelector } from 'react-redux';

import { ThemeProvider } from '@mui/material/styles';
import { CssBaseline, StyledEngineProvider } from '@mui/material';
import axios from 'axios';
import { loginPath, baseUrl } from 'config';
import { useNavigate } from 'react-router';
// routing
import Routes from 'routes';

// defaultTheme
import themes from 'themes';

// project imports
import NavigationScroll from 'layout/NavigationScroll';

import { SnackBarProvider } from 'context/snackbarContext';
import { TextFileValuesProvider } from 'context/textFileValueContext';
import { CommonContextProvider } from 'context/commonContext';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setPrintrtTyp, setCompanyInfo } from 'commonSlice/commonSlice';
// import { setPrinterType, setCompanyInfo } from 'store/actions';

const App = () => {
    const customization = useSelector((state) => state.customization);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    // useEffect(() => {
    //     function getCompany() {
    //         axios.get(baseUrl + '/getCompanyDetails').then((res) => {
    //             dispatch(setCompanyInfo(res.data[0]));
    //         });
    //     }
    //     function getPrinter() {
    //         axios({
    //             method: 'get',
    //             url: baseUrl + '/getPrinterName'
    //         })
    //             .then((res) => {
    //                 dispatch(setPrintrtTyp(res.data));
    //             })
    //             .catch((err) => {
    //                 if (err.response.status === 403) {
    //                     navigate(loginPath);
    //                 }
    //             });
    //     }
    //     getCompany();
    //     getPrinter();
    // }, []);
    return (
        <SnackBarProvider>
            <TextFileValuesProvider>
                <CommonContextProvider>
                    <StyledEngineProvider injectFirst>
                        <ThemeProvider theme={themes(customization)}>
                            <CssBaseline />
                            <NavigationScroll>
                                <Routes />
                            </NavigationScroll>
                        </ThemeProvider>
                    </StyledEngineProvider>
                </CommonContextProvider>
            </TextFileValuesProvider>
        </SnackBarProvider>
    );
};

export default App;
