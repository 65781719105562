import { lazy } from 'react';
import Loadable from 'ui-component/Loadable';

import SalesLayOut from 'views/Sales-Sections/SalesLayOut';
const Purchase = Loadable(lazy(() => import('../views/Purchase-Sections/purchase/purchase')));
const PruchaseReturn = Loadable(lazy(() => import('../views/purchase/purchaseReturn/PruchaseReturn')));
const SalesPos = Loadable(lazy(() => import('views/Sales-Sections/salesPos/SalesPos')));

const PurchaseRoute = {
    path: '/purchase',
    // element: <MinimalLayout />,
    // element: <SalesLayOut />,
    element: <SalesLayOut />,
    children: [
        {
            path: 'purchasebill',
            element: <Purchase />
        },
        {
            path: 'purchasebillReturn',
            element: <PruchaseReturn />
        },
        {
            path: 'orderForm',
            element: <SalesPos />
        }
    ]
};

export default PurchaseRoute;
