import { lazy } from 'react';

// project imports
import Loadable from 'ui-component/Loadable';
import MinimalLayout from 'layout/MinimalLayout';
// import AdminLogin from 'admin/Authentication/AdminLogin';
// import Admin from 'admin/Admin';
// import AdminLogin from 'admin/Authentication/AdminLogin';

// login option 3 routing
const AuthLogin3 = Loadable(lazy(() => import('views/pages/authentication/authentication3/Login3')));
const AuthRegister3 = Loadable(lazy(() => import('views/pages/authentication/authentication3/Register3')));
const Admin = Loadable(lazy(() => import('admin/Admin')));
const AdminLogin = Loadable(lazy(() => import('admin/Authentication/AdminLogin')));

// ==============================|| AUTHENTICATION ROUTING ||============================== //

const AuthenticationRoutes = {
    path: '/',
    element: <MinimalLayout />,
    children: [
        {
            path: '/login',
            element: <AuthLogin3 />
        },
        {
            path: '/pages/register/register3',
            element: <AuthRegister3 />
        },
        {
            path: '/admin',
            element: <AdminLogin />
        },
        {
            path: '/admin/main',
            element: <Admin />
        }
    ]
};

export default AuthenticationRoutes;
