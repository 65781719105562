// assets
import { IconReportMoney, IconTruckReturn } from '@tabler/icons';
// constant
const icons = {
    IconReportMoney,
    IconTruckReturn
};

const sales = {
    id: 'sales',
    type: 'group',
    children: [
        {
            id: 'sales',
            title: 'Manage Sales',
            type: 'collapse',
            icon: icons.IconReportMoney,
            children: [
                {
                    id: 'sales-pos',
                    title: 'Sales POS',
                    type: 'item',
                    url: '/sales/salesPos',
                    icon: icons.IconReportMoney,
                    breadcrumbs: false
                },
                {
                    id: 'salesReturn',
                    title: 'Sales Return',
                    type: 'item',
                    url: '/sales/salesReturn',
                    icon: icons.IconTruckReturn,
                    breadcrumbs: false
                }
            ]
        }
    ]
};
export default sales;
// {
//     id: 'sales-pos',
//     title: 'Sales POS',
//     type: 'item',
//     url: '/sales/pos',
//     icon: icons.IconReportMoney,
//     breadcrumbs: false
// }
