import { createSlice } from '@reduxjs/toolkit';

const purchaseTableDataSlice = createSlice({
    name: 'purchaseTableData',
    initialState: [],
    reducers: {
        addItemToTableData: (state, action) => {
            state.push(action.payload);
        },
        updateTableRowValue: (state, action) => {
            const { index, free, discAmt, qty, Cost, Tax1, Rate1, Rate2, exp_date, batch } = action.payload;
            state[index].free = free;
            state[index].discAmt = discAmt;
            state[index].qty = qty;
            state[index].Cost = Cost;
            state[index].Rate1 = Rate1;
            state[index].Rate2 = Rate2;
            state[index].exp_date = exp_date;
            state[index].batch = batch;
            state[index].total = qty * Cost - discAmt;
            state[index].taxAmt = ((qty * Cost - discAmt) * Tax1) / 100;
            state[index].netValue = qty * Cost - discAmt + ((qty * Cost - discAmt) * Tax1) / 100;
            if (Cost == 0 || Rate1 == 0) {
                state[index].perc = (0).toFixed(2);
            } else {
                state[index].perc = (((Rate1 - Cost) / Cost) * 100).toFixed(2);
            }
        },

        deleteItemFromTableData: (state, action) => {
            const index = state.findIndex((item) => item.ItemNo === action.payload.ItemNo);
            if (index !== -1) {
                state.splice(index, 1);
            }
        },
        clearTableData: (state, action) => {
            return [];
        },
        // New reducer to replace the array with new data
        replaceTableData: (state, action) => {
            return action.payload; // Set the state to the new data
        }
    }
});

export const {
    addItemToTableData,

    deleteItemFromTableData,
    clearTableData,
    replaceTableData,

    updateTableRowValue
} = purchaseTableDataSlice.actions;
export default purchaseTableDataSlice.reducer;
