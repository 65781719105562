import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    discPer: 0,
    discAmtByPer: 0,
    discAmt: 0,
    taxPer: 0,
    taxAmt: 0,
    other: 0,
    total: 0,
    netTotal: 0
};

const discAndTaxSlice = createSlice({
    name: 'discAndTax',
    initialState,
    reducers: {
        setDiscPer: (state, action) => {
            state.discPer = action.payload;
        },
        setDiscAmtByPer: (state, action) => {
            state.discAmtByPer = action.payload;
        },
        setDiscAmt: (state, action) => {
            state.discAmt = action.payload;
        },
        setTaxPer: (state, action) => {
            state.taxPer = action.payload;
        },
        setTaxAmt: (state, action) => {
            state.taxAmt = action.payload;
        },
        setOther: (state, action) => {
            state.other = action.payload;
        },
        setTotal: (state, action) => {
            state.total = action.payload;
        },
        setNetTotal: (state, action) => {
            state.netTotal = action.payload;
        },
        setToDefaultCalc: (state) => {
            // Reset the state to its initial/default values directly
            state.discPer = 0;
            state.discAmtByPer = 0;
            state.discAmt = 0;
            state.taxPer = 0;
            state.taxAmt = 0;
            state.other = 0;
            state.total = 0;
            state.netTotal = 0;
        }
    }
});

export const { setDiscPer, setDiscAmtByPer, setDiscAmt, setTaxPer, setTaxAmt, setOther, setTotal, setNetTotal, setToDefaultCalc } =
    discAndTaxSlice.actions;

export default discAndTaxSlice.reducer;
