import { Box, Grid, Button, Modal, Typography, Stack, Backdrop, CircularProgress } from '@mui/material';
import React from 'react';
import { useTheme } from '@mui/material';
import axios from 'axios';

import { useSelector, useDispatch } from 'react-redux';
import Calculation from './Calculation';
import { useSnackBar } from 'context/snackbarContext';
import { clearTableData } from 'views/Sales-Sections/salesReturn/slice/tableDataSlice';
import { setToDefaultCalc } from '../slice/discAndTaxSlice';
import { updateSelected } from '../slice/returnHeaderSlice';
import { useEffect } from 'react';
import { baseUrl } from 'config';
import { loginPath } from 'config';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router';
// import { resetState } from 'redux/returnHeaderSlice';

//--------// APIS_USED_FOR_THIS_COMPONENT //---------//

const getReturnNo = '/getMaxBillSr';
const deleteBill = '/deleteReturnInvoice';
const customerOutletDealer = '/getMasterAndSalesItem';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '0px',
    boxShadow: 24,
    p: 2,
    borderRadius: 2
};

function Footer() {
    const navigate = useNavigate();
    const [open, setOpen] = React.useState(false);
    const [progress, setProgress] = React.useState(false);
    const [modalTyp, setModalTyp] = React.useState('');

    const theme = useTheme();
    const { showSnackbar } = useSnackBar();
    const dispatch = useDispatch();
    const returnHeader = useSelector((state) => state.returnHeader);
    const tableData = useSelector((state) => state.tableData); //salesReturnbill
    const discPer = useSelector((state) => state.discAndTax.discPer); //discPerc
    // const discAmtByPer = useSelector((state) => state.discAndTax.discAmtByPer);
    const discAmt = useSelector((state) => state.discAndTax.discAmt); //discAmt
    const taxPer = useSelector((state) => state.discAndTax.taxPer); //tax
    const taxAmt = useSelector((state) => state.discAndTax.taxAmt); //taxAmt
    const other = useSelector((state) => state.discAndTax.other);
    const total = useSelector((state) => state.discAndTax.total); //total
    const netTotal = useSelector((state) => state.discAndTax.netTotal); //netTotal

    // const companyInfo = useSelector((state) => state.customizationReducer.companyInfo);

    useEffect(() => {
        handleRetNO();
    }, []);

    // console.log(printerType);

    function handleRetNO() {
        axios
            .get(baseUrl + customerOutletDealer)
            .then((res) => {
                dispatch(
                    updateSelected({
                        customer: res.data[0][0],
                        contact: res.data[0][0].Ph,
                        outlet: res.data[1][0],
                        dealer: res.data[2][0]
                    })
                );
            })
            .catch((error) => {
                if (error.response && error.response.status === 403) {
                    navigate(loginPath);
                }
            });
        axios
            .get(baseUrl + getReturnNo)
            .then((res) => {
                dispatch(
                    updateSelected({
                        retNo: res.data[0].BillNo,
                        retBill: res.data[0].BillNo
                    })
                );
            })
            .catch((error) => {
                if (error.response && error.response.status === 403) {
                    navigate(loginPath);
                }
            });
    }

    const handleSubmit = () => {
        let saveSalesReturn;
        if (returnHeader.edit) {
            saveSalesReturn = '/updateReturnInvoice';
        } else {
            saveSalesReturn = '/saveReturnInvoice';
        }
        // const saveSalesReturn = '/saveReturnInvoice';
        setProgress(true);
        if (tableData.length > 0) {
            const salesReturn = {
                salesReturnbill: tableData,
                header: returnHeader,
                discPerc: discPer,
                discAmt: discAmt,
                tax: taxPer,
                taxAmt: taxAmt,
                total: total,
                netTotal: netTotal,
                otherChrgs: other,
                netQty: tableData.length
            };
            axios
                .post(baseUrl + saveSalesReturn, salesReturn)
                .then((res) => {
                    if (res.data) {
                        dispatch(clearTableData());
                        handleRetNO();
                        setOpen(false);
                        setProgress(false);
                        dispatch(setToDefaultCalc());
                        dispatch(
                            updateSelected({
                                edit: false,
                                date: dayjs()
                            })
                        );
                    }
                })
                .catch((error) => {
                    if (error.response && error.response.status === 403) {
                        navigate(loginPath);
                    }
                });
        } else {
            setProgress(false);
            showSnackbar('warning', 'Add Products...');
        }
    };
    const openModal = (typ) => {
        setModalTyp(typ);
        if (typ === 'save') {
            if (tableData.length > 0) {
                setOpen(true);
            } else {
                showSnackbar('warning', 'Add Products...');
            }
        } else {
            setOpen(true);
        }
    };

    //handleDeleteInvoice
    const handleDeleteRet = () => {
        setProgress(true);

        axios
            .post(baseUrl + deleteBill, { BillNo: returnHeader.retNo })
            .then((res) => {
                if (res.data.success) {
                    setOpen(false);
                    dispatch(clearTableData());
                    dispatch(setToDefaultCalc());
                    showSnackbar('success', 'Deleted successfuly');
                    handleRetNO();
                    dispatch(
                        updateSelected({
                            edit: false
                        })
                    );
                } else {
                    showSnackbar('error', 'something went wrong, Try again...');
                }
                setProgress(false);
            })
            .catch((error) => {
                if (error.response && error.response.status === 403) {
                    navigate(loginPath);
                }
            });
    };
    const handleClearData = () => {
        window.location.reload();
    };
    const handleClose = () => {
        setProgress(false);
        setOpen(false);
    };
    const handlePrint = () => {
        axios
            .post(baseUrl + '/getTermelPrintItems', { BillNo: returnHeader.retNo, typ: 'SR' })
            .then((response) => {
                if (response.data[1].length > 0) {
                    navigate('/printer/thermalPrint', {
                        state: { data: response.data[0][0], tableData: response.data[1] }
                    });
                } else {
                    navigate('/printer/a4', {
                        state: { data: response.data[0][0], tableData: response.data[1] }
                    });
                }
            })

            .catch((error) => {
                if (error.response && error.response.status === 403) {
                    navigate(loginPath);
                }
            });
    };

    return (
        <>
            <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={progress}>
                <CircularProgress color="inherit" />
            </Backdrop>
            <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                {modalTyp === 'save' ? (
                    <Box sx={style}>
                        <Typography variant="h3" gutterBottom>
                            Save
                        </Typography>
                        <Typography variant="body2" gutterBottom>
                            You want to proceed...
                        </Typography>
                        <Stack direction={'row'} justifyContent={'end'} gap={2}>
                            <Button onClick={handleClose}>No</Button>
                            <Button variant="contained" onClick={handleSubmit}>
                                Yes
                            </Button>
                        </Stack>
                    </Box>
                ) : (
                    <Box sx={style}>
                        <Typography variant="h3" gutterBottom>
                            Delete
                        </Typography>
                        <Typography variant="body2" gutterBottom>
                            Are you sure want to delete?
                        </Typography>
                        <Stack direction={'row'} justifyContent={'end'} gap={2}>
                            <Button onClick={handleClose}>No</Button>
                            <Button variant="contained" color="error" onClick={handleDeleteRet}>
                                Yes
                            </Button>
                        </Stack>
                    </Box>
                )}
            </Modal>
            <Box>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6} md={4}>
                        <Grid container spacing={1}>
                            <Grid item xs={4}>
                                <Button
                                    onClick={handlePrint}
                                    disabled={!returnHeader.edit}
                                    fullWidth
                                    variant="contained"
                                    color="secondary"
                                    sx={{ background: `${theme.palette.grey[900]}`, fontSize: 16 }}
                                >
                                    Print
                                </Button>
                            </Grid>
                            <Grid item xs={4}>
                                <Button
                                    fullWidth
                                    disabled={!returnHeader.edit}
                                    variant="contained"
                                    sx={{ background: `${theme.palette.error.dark}`, fontSize: 16 }}
                                    // onClick={handleDeleteRet}
                                    onClick={() => openModal('delete')}
                                >
                                    delete
                                </Button>
                            </Grid>
                            <Grid item xs={4}>
                                <Button
                                    fullWidth
                                    variant="contained"
                                    sx={{ background: `${theme.palette.success.dark}`, fontSize: 16 }}
                                    onClick={() => openModal('save')}
                                >
                                    save
                                </Button>
                            </Grid>
                            <Grid item xs={4}>
                                <Button
                                    fullWidth
                                    variant="contained"
                                    sx={{ background: `${theme.palette.grey[500]}`, fontSize: 16 }}
                                    onClick={handleClearData}
                                >
                                    Clear
                                </Button>
                            </Grid>
                            <Grid item xs={4}>
                                <Button fullWidth variant="contained" sx={{ background: `${theme.palette.grey[500]}`, fontSize: 16 }}>
                                    button
                                </Button>
                            </Grid>
                            <Grid item xs={4}>
                                <Button fullWidth variant="contained" sx={{ background: `${theme.palette.grey[500]}`, fontSize: 16 }}>
                                    button
                                </Button>
                            </Grid>
                            <Grid item xs={4}>
                                <Button fullWidth variant="contained" sx={{ background: `${theme.palette.grey[500]}`, fontSize: 16 }}>
                                    button
                                </Button>
                            </Grid>
                            <Grid item xs={4}>
                                <Button fullWidth variant="contained" sx={{ background: `${theme.palette.grey[500]}`, fontSize: 16 }}>
                                    button
                                </Button>
                            </Grid>
                            <Grid item xs={4}>
                                <Button fullWidth variant="contained" sx={{ background: `${theme.palette.grey[500]}`, fontSize: 16 }}>
                                    button
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <Calculation />
                    </Grid>
                </Grid>
            </Box>
        </>
    );
}

export default Footer;
