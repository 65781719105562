import { useEffect, useState } from 'react';
import {
    Box,
    Autocomplete,
    TextField,
    OutlinedInput,
    InputAdornment,
    Divider,
    Grid,
    Card,
    Typography,
    IconButton,
    Button,
    Dialog,
    DialogContent
} from '@mui/material';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { IconSearch } from '@tabler/icons';
import { useTheme } from '@mui/material/styles';
import axios from 'axios';
import { baseUrl } from 'config';
import { useDispatch } from 'react-redux';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router';
import { addItemToTableData } from 'views/Sales-Sections/salesReturn/slice/tableDataSlice';
import { addItemToTableData as addItemToPos } from '../salesPos/slice/tableDataPosSlice';

import MultiUnitWindow from '../salesReturn/components/MultiUnitWindow';
import BatchWindow from '../salesReturn/components/BatchWindowPos';
import BatchWindowPos from '../salesPos/components/BatchWindow';
import MultiUnitWindowPos from '../salesPos/components/MultiUnitWindowPos';
import CloseIcon from '@mui/icons-material/Close';
import { loginPath } from 'config';

function DrawerItems({ typ, allProduct, expiry, batchWise }) {
    const theme = useTheme();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [allProducts, setAllProducts] = useState([]);
    const [draweData, setDrawerData] = useState([]);
    const [openModal, setOpenModal] = useState(false);
    const [searchName, setSearchName] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [categoryMenuItems, setCategoryMenuItems] = useState();
    const [currProduct, setCurrProduct] = useState(null);
    const [modalTyp, setModalTyp] = useState(null);
    const itemsPerPage = 198;
    const [itemQuantities, setItemQuantities] = useState([]);

    useEffect(() => {
        getCategory();
    }, []);
    useEffect(() => {
        setDrawerData(allProduct);
        setAllProducts(allProduct);
    }, [allProduct]);

    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = currentPage * itemsPerPage;
    const itemsToRender = draweData.slice(startIndex, endIndex);

    const handleSearchField = () => {
        var updatedList = [...allProducts];

        updatedList = updatedList.filter((item) => {
            return item.ItemNameTextField.toLowerCase().includes(searchName.toLowerCase());
        });

        setDrawerData(updatedList);
    };

    const getCategory = () => {
        setDrawerData(allProduct);
        setAllProducts(allProduct);
        axios({
            method: 'post',
            url: baseUrl + '/getCategoryOrSubcategory',
            data: {
                typ: 'PRODUCT GROUP'
            }
        })
            .then((res) => {
                setCategoryMenuItems(res.data);
            })
            .catch((err) => {
                if (err.response.status === 403) {
                    navigate(loginPath);
                }
            });
    };

    const handelCategory = (value) => {
        const filtered = allProducts.filter((name) => name.Size == value);
        setDrawerData(filtered);
    };

    const handleNextClick = () => {
        setCurrentPage(currentPage + 1);
    };

    const handlePrevClick = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };
    const clearField = () => {
        setSearchName('');
        setDrawerData(allProducts);
    };

    const handleAddItem = (item, quantity) => {
        quantity = quantity ?? 1;
        if (item) {
            const newItem = {
                ...item,
                qty: quantity,
                taxAmt: ((1 * item.Rate1) / 100) * item.Tax1,
                total: item.taxAmt + item.Rate1 * quantity,
                exp_date: dayjs().format('DD/MM/YYYY')
            };

            setCurrProduct(newItem);

            if (item.Typ !== item.SecUnit) {
                handleClickOpen('multiUnit');
            } else if (batchWise === 'YES BATCH') {
                handleClickOpen('batchWise');
            } else {
                // If neither condition is met, add the item to the table data
                if (from === 'salesReturn') {
                    dispatch(addItemToTableData(newItem));
                } else {
                    dispatch(addItemToPos(newItem));
                }
            }
        }
    };

    // Modal open
    const handleClickOpen = (typ) => {
        setModalTyp(typ);

        setOpenModal(true);
    };

    // Modal close
    const handleClose = () => {
        setOpenModal(false);
    };

    // Function to increase the quantity for an item by index
    const increaseQuantity = (index) => {
        setItemQuantities((prevQuantities) => {
            const newQuantities = [...prevQuantities];
            newQuantities[index] = (newQuantities[index] || 1) + 1;
            return newQuantities;
        });
    };

    // Function to decrease the quantity for an item by index
    const decreaseQuantity = (index) => {
        setItemQuantities((prevQuantities) => {
            const newQuantities = [...prevQuantities];
            const currentQuantity = newQuantities[index] || 1;
            if (currentQuantity > 0) {
                newQuantities[index] = currentQuantity - 1;
            }
            return newQuantities;
        });
    };
    // if (typ === 'salesReturn') {
    //     unitWindow = <BatchWindow product={currProduct} modalClose={handleClose} batchWise={batchWise} expiry={expiry} typ="salesReturn" />;
    //     batchWindow = <MultiUnitWindow product={currProduct} expiry={expiry} modalClose={handleClose} />;
    // } else {
    //     unitWindow = (
    //         <MultiUnitWindowPos product={currProduct} modalClose={handleClose} batchWise={batchWise} expiry={expiry} typ="salesBill" />
    //     );
    //     batchWindow = <BatchWindowPos product={currProduct} modalClose={handleClose} batchWise={batchWise} expiry={expiry} />;
    // }

    return (
        <Box>
            <Dialog open={openModal} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                <DialogContent sx={{ p: 0 }}>
                    <DialogContent sx={{ p: 0 }}>
                        {typ === 'salesReturn' ? (
                            <>
                                {modalTyp !== 'multiUnit' ? (
                                    <BatchWindow
                                        product={currProduct}
                                        modalClose={handleClose}
                                        batchWise={batchWise}
                                        expiry={expiry}
                                        typ="salesReturn"
                                    />
                                ) : (
                                    <MultiUnitWindow product={currProduct} expiry={expiry} modalClose={handleClose} />
                                )}
                            </>
                        ) : (
                            <>
                                {modalTyp !== 'multiUnit' ? (
                                    <MultiUnitWindowPos
                                        product={currProduct}
                                        modalClose={handleClose}
                                        batchWise={batchWise}
                                        expiry={expiry}
                                        typ="salesBill"
                                    />
                                ) : (
                                    <BatchWindowPos product={currProduct} modalClose={handleClose} batchWise={batchWise} expiry={expiry} />
                                )}
                            </>
                        )}
                    </DialogContent>
                </DialogContent>
            </Dialog>

            <Box>
                <Grid container spacing={2} alignItems={'center'}>
                    <Grid item xs={12}>
                        <Autocomplete
                            disablePortal
                            id="combo-box-demo"
                            options={categoryMenuItems}
                            getOptionLabel={(option) => option.Typ}
                            onChange={(e, v) => {
                                handelCategory(v.Typ);
                            }}
                            renderInput={(params) => <TextField {...params} label="Category" fullWidth size="small" />}
                        />
                    </Grid>
                    <Grid item xs={10}>
                        <OutlinedInput
                            size="small"
                            sx={{ width: '100%', pr: 1, pl: 2, my: 2 }}
                            id="input-search-profile"
                            value={searchName}
                            onChange={(e) => setSearchName(e.target.value)}
                            placeholder="Search products"
                            startAdornment={
                                <InputAdornment position="start">
                                    <IconSearch stroke={1.5} size="1rem" color={theme.palette.grey[500]} />
                                </InputAdornment>
                            }
                            endAdornment={
                                <InputAdornment position="end">
                                    {searchName !== '' ? (
                                        <IconButton
                                            size="small"
                                            aria-label="toggle password visibility"
                                            onClick={clearField}
                                            // onMouseDown={handleMouseDownPassword}
                                        >
                                            <CloseIcon />
                                        </IconButton>
                                    ) : null}
                                </InputAdornment>
                            }
                            aria-describedby="search-helper-text"
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <Button variant="contained" onClick={handleSearchField}>
                            <IconSearch />
                        </Button>
                    </Grid>
                </Grid>
            </Box>
            <Divider />
            <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
                <Button variant="contained" color="primary" onClick={handlePrevClick} disabled={currentPage === 1}>
                    Previous
                </Button>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleNextClick}
                    disabled={itemsToRender.length < itemsPerPage || endIndex >= allProducts.length}
                    endIcon={<ArrowForwardIosIcon />}
                >
                    Next
                </Button>
            </Box>
            <Box sx={{ mt: 2 }}>
                <Grid container spacing={1}>
                    {itemsToRender.map((item, index) => {
                        return (
                            <Grid item xs={6} sm={4} key={item.id}>
                                <Card
                                    sx={{
                                        p: 1,
                                        width: '100%',
                                        height: '160px',
                                        '&:hover': {
                                            backgroundColor: '#D3D3D3'
                                        }
                                    }}
                                >
                                    <Box onClick={() => handleAddItem(item, itemQuantities[index])}>
                                        <img
                                            style={{ width: '100%', height: '80px', objectFit: 'contain' }}
                                            src={
                                                item.photo
                                                    ? item.photo
                                                    : 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcThtjU0d_BQklzBkT7Hn7t48a5yaBVWIJa4i6PcFbFgt91JYcN-FPV0laysIBBD-VC-p-s&usqp=CAU'
                                            }
                                            alt="zxc"
                                        />
                                        <input
                                            type="text"
                                            value={item.ItemNameTextField}
                                            style={{
                                                width: '-webkit-fill-available',
                                                border: 'none',
                                                whiteSpace: 'nowrap',
                                                overflow: 'hidden',
                                                textOverflow: 'ellipsis'
                                            }}
                                        />
                                        <Box display="flex" alignItems="center" justifyContent="space-between">
                                            <Typography variant="body2" color="text.secondary">
                                                Price: <span>{item.Rate1}</span>
                                            </Typography>
                                            {/* <IconButton
                                            color="primary"
                                            aria-label="upload picture"
                                            component="label"
                                            sx={{ color: 'rgb(94 53 177)', p: 0 }}
                                            onClick={() => handleAddItem(item, itemQuantities[index])}
                                            >
                                            <AddCircleIcon />
                                        </IconButton> */}
                                        </Box>
                                    </Box>
                                    <Box display="flex" alignItems="center" justifyContent="center" gap={'2px'}>
                                        <IconButton
                                            color="primary"
                                            aria-label="Decrease quantity"
                                            component="label"
                                            sx={{ color: 'rgb(94 53 177)', p: 0 }}
                                            onClick={() => decreaseQuantity(index)}
                                        >
                                            <KeyboardArrowLeftIcon />
                                        </IconButton>
                                        <Typography variant="body2" color="text.secondary">
                                            Qty: {itemQuantities[index] || 1}
                                        </Typography>
                                        <IconButton
                                            color="primary"
                                            aria-label="Increase quantity"
                                            component="label"
                                            sx={{ color: 'rgb(94 53 177)', p: 0 }}
                                            onClick={() => increaseQuantity(index)}
                                        >
                                            <NavigateNextIcon />
                                        </IconButton>
                                    </Box>
                                </Card>
                            </Grid>
                        );
                    })}
                </Grid>
            </Box>
        </Box>
    );
}

export default DrawerItems;
