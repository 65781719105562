import { lazy } from 'react';
import SalesLayOut from 'views/Sales-Sections/SalesLayOut';
import Loadable from 'ui-component/Loadable';
// import ThermalPrint from 'views/PrintLayouts/ThermalPrint';
import BatchPrint from 'views/others/BatchPrint';
// const BatchPrint = Loadable(lazy(() => import('views/others/BatchPrint')));
const ThermalPrint = Loadable(lazy(() => import('views/others/ThermalPrint')));
const A4Print = Loadable(lazy(() => import('views/others/A4Print')));

const PrinterRoutes = {
    path: 'printer',
    element: <SalesLayOut />,
    children: [
        {
            path: 'thermalPrint',
            element: <ThermalPrint />
        },
        {
            path: 'a4',
            element: <A4Print />
        },
        {
            path: 'batchPrint',
            element: <BatchPrint />
        }
    ]
};
export default PrinterRoutes;
