import {
    IconCashBanknote,
    IconCoin,
    IconReportAnalytics,
    IconReportMoney,
    IconStorm,
    IconTruckReturn,
    IconFileAnalytics,
    IconDotsCircleHorizontal,
    IconCategory,
    IconShieldLock
} from '@tabler/icons';

const icons = {
    IconCashBanknote,
    IconCoin,
    IconReportAnalytics,
    IconReportMoney,
    IconStorm,
    IconTruckReturn,
    IconFileAnalytics,
    IconDotsCircleHorizontal,
    IconCategory,

    IconShieldLock
};

const others = {
    id: 'others',
    type: 'group',
    children: [
        {
            id: 'category/subcategory',
            title: 'Others',
            type: 'collapse',
            icon: icons.IconCategory,
            children: [
                {
                    id: 'category/subcategory',
                    title: 'Category/Sub',
                    type: 'item',
                    url: '/others/createcategory',
                    icon: icons.IconBrandAsana,
                    breadcrumbs: false
                },
                {
                    id: 'userPrivilege',
                    title: 'User Privilege',
                    type: 'item',
                    url: '/others/userPrivilege',
                    icon: icons.IconShieldLock,
                    breadcrumbs: false
                }
            ]
        }
    ]
};
export default others;
