import dashboard from './dashboard';
import pages from './pages';
import utilities from './utilities';
import other from './other';
import sales from './sales';
import purchase from './purchase';
import reports from './reports';
import accounts from './accounts';
import others from './others';
import settings from './settings';

// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
    items: [dashboard, sales, purchase, accounts, reports, others, settings]
};

export default menuItems;
