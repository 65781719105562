// import React from 'react';
import PropTypes from 'prop-types';
import { Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Box, Typography, Modal, Stack } from '@mui/material';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { addItemToTableData } from 'views/Sales-Sections/salesReturn/slice/tableDataSlice';
import dayjs from 'dayjs';
import { baseUrl } from 'config';
import { loginPath } from 'config';

//--------// APIS_USED_FOR_THIS_COMPONENT //---------//
const getBatch = '/generateBatchData';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '0px solid #000',
    boxShadow: 24,
    p: 2
};

function BatchWindow({ product, modalClose, expiry }) {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [batchData, setBatchData] = useState([]);
    const [open, setOpen] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.post(baseUrl + getBatch, { item: product.ItemNameTextField });

                if (response.status === 403) navigate(loginPath);
                if (response.data.length > 0) {
                    setBatchData(response.data);
                } else {
                    modalClose();
                    const currProduct = {
                        ...product,
                        qty: 1,
                        taxAmt: ((1 * product.Rate1) / 100) * product.Tax1,
                        // total: currProduct.taxAmt + currProduct.Rate1,
                        exp_date: dayjs().format('DD/MM/YYYY')
                    };
                    currProduct.total = currProduct.taxAmt + currProduct.Rate1;

                    dispatch(addItemToTableData(currProduct));
                }
            } catch (error) {
                console.error('Error:', error);
            }
        };
        fetchData();
    }, []);
    const handleRowClick = (data) => {
        const expDate = data.exp_date.split('-');
        const day = parseInt(expDate[0]);
        const month = parseInt(expDate[1]) - 1; // Months are 0-based (0 = January, 1 = February, ...)
        const year = 2000 + parseInt(expDate[2]);
        const parsedDate = new Date(year, month, day);
        const expDateConvert = parsedDate;

        if (expiry === 'YES EXPIRY') {
            if (parsedDate <= new Date()) {
                setOpen(true);
            } else {
                const batchItem = {
                    ...product,
                    Rate1: data.price,
                    Cost: data.cost,
                    Pack: data.pack,
                    batch: data.batch_no,
                    curr_stock: data.curr_stk,
                    qty: 1,
                    taxAmt: ((1 * product.Rate1) / 100) * product.Tax1,
                    total: (product.taxAmt + product.Rate1) * 1,
                    exp_date: expDateConvert
                };
                dispatch(addItemToTableData(batchItem));
                modalClose();
            }
        } else {
            const batchItem = {
                ...product,
                Rate1: data.price,
                Cost: data.cost,
                Pack: data.pack,
                batch: data.batch_no,
                curr_stock: data.curr_stk,
                qty: 1,
                taxAmt: ((1 * product.Rate1) / 100) * product.Tax1,
                total: (product.taxAmt + product.Rate1) * 1,
                exp_date: dayjs().format('DD/MM/YYYY')
            };
            dispatch(addItemToTableData(batchItem));
            modalClose();
        }
    };

    // const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    return (
        <>
            <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h4" component="h2">
                        Warning
                    </Typography>
                    <Typography id="modal-modal-description" variant="h3" sx={{ mt: 2 }} color={'error'}>
                        This item is expired
                    </Typography>
                    <Stack direction={'row'} justifyContent={'end'}>
                        <Button onClick={handleClose} variant="contained" color="info">
                            OK
                        </Button>
                    </Stack>
                </Box>
            </Modal>
            <Box sx={{ width: 580, maxWidth: '100%' }}>
                <Box sx={{ py: 1, textAlign: 'center' }}>
                    <Typography variant="h3">Batch</Typography>
                </Box>
                <TableContainer>
                    <Table aria-label="simple table">
                        <TableHead sx={{ background: 'grey', color: '#fff' }}>
                            <TableRow>
                                <TableCell sx={{ color: 'white' }} align="center">
                                    Batch
                                </TableCell>
                                <TableCell align="center" sx={{ color: 'white' }}>
                                    Cost
                                </TableCell>
                                <TableCell align="center" sx={{ color: 'white' }}>
                                    Stock
                                </TableCell>
                                <TableCell align="center" sx={{ color: 'white' }}>
                                    Price
                                </TableCell>
                                <TableCell align="center" sx={{ color: 'white' }}>
                                    Pack
                                </TableCell>
                                <TableCell align="center" sx={{ color: 'white' }}>
                                    Exp date
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {batchData.map((batch, index) => (
                                <TableRow
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    key={index}
                                    onClick={() => handleRowClick(batch)}
                                >
                                    <TableCell component="th" scope="row" align="center">
                                        {batch.batch_no}
                                    </TableCell>
                                    <TableCell align="center">{batch.cost}</TableCell>
                                    <TableCell align="center">{batch.curr_stk}</TableCell>
                                    <TableCell align="center">{batch.price}</TableCell>
                                    <TableCell align="center">{batch.pack}</TableCell>
                                    <TableCell align="center">{batch.exp_date}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
        </>
    );
}

export default BatchWindow;
BatchWindow.propTypes = {
    product: PropTypes.object.isRequired,
    expiry: PropTypes.string.isRequired,
    modalClose: PropTypes.func.isRequired
};
