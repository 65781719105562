import { combineReducers } from 'redux';

// Reducer imports
import customizationReducer from './customizationReducer';
import tableDataSlice from '../views/Sales-Sections/salesReturn/slice/tableDataSlice';
import returnHeaderSlice from '../views/Sales-Sections/salesReturn/slice/returnHeaderSlice';
import discAndTaxSlice from 'views/Sales-Sections/salesReturn/slice/discAndTaxSlice';
import posHeaderSlice from 'views/Sales-Sections/salesPos/slice/posHeaderSlice';
import discAndTaxPosSlice from 'views/Sales-Sections/salesPos/slice/discAndTaxPosSlice';
import tableDataPosSlice from 'views/Sales-Sections/salesPos/slice/tableDataPosSlice';
import commonSlice from 'commonSlice/commonSlice';
import puchaseHeaderSlice from 'views/Purchase-Sections/purchase/slice/puchaseHeaderSlice';
import purchaseDiscAndTaxSlice from 'views/Purchase-Sections/purchase/slice/purchaseDiscAndTaxSlice';
import purchaseTableDataSlice from 'views/Purchase-Sections/purchase/slice/purchaseTableDataSlice';

// Combine reducers
const reducer = combineReducers({
    common: commonSlice,
    // products

    customization: customizationReducer,

    tableData: tableDataSlice,
    returnHeader: returnHeaderSlice,
    discAndTax: discAndTaxSlice,

    // sales pos reducers
    posHeader: posHeaderSlice,
    discAndTaxPos: discAndTaxPosSlice,
    tableDataPos: tableDataPosSlice,

    //purchase reducers
    purchaseHeader: puchaseHeaderSlice,
    purchaseDiscAndTax: purchaseDiscAndTaxSlice,
    purchaseTableData: purchaseTableDataSlice
});

export default reducer;
