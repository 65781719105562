import { IconSettings } from '@tabler/icons';
// constant
const icons = {
    IconSettings
};

const settings = {
    id: 'settings',
    type: 'group',
    children: [
        {
            id: 'settings',
            title: 'Settings',
            type: 'item',
            url: '/settings',
            icon: icons.IconSettings
        }
    ]
};
export default settings;
