// TextFileValuesContext.js
import React, { createContext, useState, useContext } from 'react';

const TextFileValuesContext = createContext();

export const TextFileValuesProvider = ({ children }) => {
    // const [textFileValues, setTextFileValues] = useState();
    const [textFileValues, setTextFileValues] = useState({
        batchStaus: '',
        GodownStatus: '',
        CurrencyType: '',
        CurrencyTypeShort: '',
        userWiseParty: 'false'
    });

    return <TextFileValuesContext.Provider value={{ textFileValues, setTextFileValues }}>{children}</TextFileValuesContext.Provider>;
};

export const useTextFileValues = () => {
    return useContext(TextFileValuesContext);
};
