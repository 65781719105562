import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';

// dashboard routing

// utilities routing
const Payment = Loadable(lazy(() => import('../views/Accounts/Payment')));
const Receipt = Loadable(lazy(() => import('../views/Accounts/Receipt')));

// sample page routing
const SamplePage = Loadable(lazy(() => import('views/sample-page')));

const AccountsRoutes = {
    path: '/',
    element: <MainLayout />,
    children: [
        {
            path: 'accounts',
            // element: <GstReports />,
            children: [
                {
                    path: 'payment',
                    element: <Payment />
                },
                {
                    path: 'receipt',
                    element: <Receipt />
                }
            ]
        }
    ]
};

export default AccountsRoutes;
