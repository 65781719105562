// Import createSlice from @reduxjs/toolkit
import { createSlice } from '@reduxjs/toolkit';

// Define initial state
const initialState = {
    printrtTyp: [],
    companyInfo: {}
};

// Create a slice
const commonSlice = createSlice({
    name: 'common',
    initialState,
    reducers: {
        // Action to set the array of printrtTyp
        setPrintrtTyp: (state, action) => {
            state.printrtTyp = action.payload;
        },
        // Action to set the companyInfo object
        setCompanyInfo: (state, action) => {
            state.companyInfo = action.payload;
        }
    }
});

// Export the actions and reducer
export const { setPrintrtTyp, setCompanyInfo } = commonSlice.actions;
export default commonSlice.reducer;
