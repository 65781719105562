import {
    IconCashBanknote,
    IconCoin,
    IconReportAnalytics,
    IconReportMoney,
    IconStorm,
    IconTruckReturn,
    IconFileAnalytics,
    IconBriefcase
} from '@tabler/icons';
// constant
const icons = {
    IconCashBanknote,
    IconCoin,
    IconReportAnalytics,
    IconReportMoney,
    IconStorm,
    IconTruckReturn,
    IconFileAnalytics,
    IconBriefcase
};

const accounts = {
    id: 'accounts',
    type: 'group',
    children: [
        {
            id: 'accounts',
            title: 'Accounts',
            type: 'collapse',
            icon: icons.IconBriefcase,
            children: [
                {
                    id: 'receipt',
                    title: ' Receipt',
                    type: 'item',
                    url: '/accounts/receipt',
                    icon: icons.IconReportMoney,
                    breadcrumbs: false
                },
                {
                    id: 'payment',
                    title: 'Payment',
                    type: 'item',
                    url: '/accounts/payment',
                    icon: icons.IconFileAnalytics,
                    breadcrumbs: false
                }
            ]
        }
    ]
};
export default accounts;
