import { useState } from 'react';
import { Box, IconButton } from '@mui/material';

import FullscreenIcon from '@mui/icons-material/Fullscreen';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';
import HomeIcon from '@mui/icons-material/Home';
import { Outlet } from 'react-router-dom';
import { useNavigate } from 'react-router';
import { styled } from '@mui/material/styles';
import axios from 'axios';
import { useEffect } from 'react';
import { baseUrl } from 'config';
import { loginPath } from 'config';

const CardWrapper = styled(Box)(({ theme }) => ({
    backgroundColor: theme.palette.secondary.light,
    color: theme.palette.secondary.dark,
    overflow: 'hidden',
    position: 'relative',
    '&:before': {
        content: '""',
        zIndex: 0,
        position: 'absolute',
        width: 210,
        height: 210,
        background: `linear-gradient(210.04deg, ${theme.palette.secondary.main} -50.94%, rgba(144, 202, 249, 0) 83.49%)`,
        borderRadius: '50%',
        top: -30,
        right: -100
    },
    '&:after': {
        content: '""',
        position: 'absolute',
        zIndex: -1,
        width: 210,
        height: 210,
        background: `linear-gradient(140.9deg, ${theme.palette.secondary.main} -14.02%, rgba(144, 202, 249, 0) 77.58%)`,
        borderRadius: '50%',
        top: -140,
        right: -60
    }
}));
function SalesLayOut() {
    const companyDetails = '/getCompanyDetails';
    const navigate = useNavigate();
    const [isFullscreen, setIsFullscreen] = useState(false);
    const [companyDetailsData, setCompanyDetailsData] = useState('');

    const handleHomeClick = () => {
        navigate('/dashboard/default');
    };
    const fetchCompanyData = async () => {
        try {
            const response = await axios.get(baseUrl + companyDetails);
            console.log('response', response.data[0].Name);
            setCompanyDetailsData(response.data[0]);
        } catch (error) {
            if (error.response && error.response.status === 403) {
                // Handle the error or navigate to another page as needed
                navigate(loginPath);
            } else {
                // Handle other types of errors
                console.error('API request failed:', error.message);
            }
        }
    };
    const handleFullscreenToggle = () => {
        if (!isFullscreen) {
            document.documentElement.requestFullscreen();
        } else {
            document.exitFullscreen();
        }
        setIsFullscreen(!isFullscreen);
    };

    return (
        <>
            <Box sx={{ width: '100%', background: 'rgb(238, 242, 246)' }}>
                <Box
                    sx={{
                        padding: '5px 15px',
                        boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 12px',
                        background: '#fff'
                    }}
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                >
                    {companyDetailsData ? <Box>{companyDetailsData.Name}</Box> : <Box>Company Name</Box>}
                    <Box>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <IconButton sx={{ fontSize: '2rem' }} aria-label="full screen" onClick={handleHomeClick}>
                                <HomeIcon fontSize="2.5rem" />
                            </IconButton>

                            <IconButton sx={{ fontSize: '2rem' }} aria-label="full screen" onClick={handleFullscreenToggle}>
                                {isFullscreen ? <FullscreenExitIcon fontSize="2.5rem" /> : <FullscreenIcon fontSize="2.5rem" />}
                            </IconButton>
                            {/* <AccountPop /> */}
                        </Box>
                    </Box>
                </Box>
                <CardWrapper>
                    <Outlet />
                </CardWrapper>
            </Box>
        </>
    );
}
export default SalesLayOut;
