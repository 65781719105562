import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Grid, IconButton } from '@mui/material';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { useNavigate } from 'react-router';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import axios from 'axios';
import { replaceTableData, clearTableData } from 'views/Sales-Sections/salesReturn/slice/tableDataSlice';

import { updateSelected } from '../slice/returnHeaderSlice';
import { setDiscPer, setDiscAmt, setTaxAmt, setTotal, setNetTotal, setToDefaultCalc, setTaxPer, setOther } from '../slice/discAndTaxSlice';
import { baseUrl } from 'config';
import { loginPath } from 'config';
import dayjs from 'dayjs';

//--------// APIS_USED_FOR_THIS_COMPONENT //---------//
const customerOutletDealer = '/getMasterAndSalesItem';
const getReturnNo = '/getMaxBillSr';
const updateBill = '/getSrToUpdate';

function SalesHeader() {
    const returnHeader = useSelector((state) => state.returnHeader);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [headerInfo, setHeaderInfo] = useState([]);
    const handleChange = (selectedDate) => {
        dispatch(
            updateSelected({
                date: selectedDate
            })
        );
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const res = await axios.get(baseUrl + customerOutletDealer);

                setHeaderInfo(res.data);
                // setPartyName(res.data[0][0]);
                dispatch(
                    updateSelected({
                        customer: res.data[0][0],
                        contact: res.data[0][0].Ph,
                        outlet: res.data[1][0],
                        dealer: res.data[2][0]
                    })
                );
            } catch (error) {
                if (error.response && error.response.status === 403) {
                    navigate(loginPath);
                }
            }
            try {
                const res = await axios.get(baseUrl + getReturnNo);
                dispatch(
                    updateSelected({
                        retNo: res.data[0].BillNo,
                        retBill: res.data[0].BillNo
                    })
                );
            } catch (error) {
                if (error.response && error.response.status === 403) {
                    navigate(loginPath);
                }
            }
        };
        fetchData();
    }, []);
    const handleIncrement = () => {
        if (returnHeader.retNo !== returnHeader.retBill) {
            dispatch(
                updateSelected({
                    retNo: returnHeader.retNo + 1
                })
            );
        }
        if (returnHeader.retNo + 1 < returnHeader.retBill) {
            dispatch(
                updateSelected({
                    edit: true
                })
            );
        } else {
            dispatch(
                updateSelected({
                    edit: false
                })
            );
        }
        handleEditReturn(returnHeader.retNo + 1);
    };

    const handleDecrement = () => {
        if (returnHeader.retNo > 0) {
            dispatch(
                updateSelected({
                    retNo: returnHeader.retNo - 1
                })
            );
        }
        if (returnHeader.retNo - 1 < returnHeader.retBill) {
            dispatch(
                updateSelected({
                    edit: true
                })
            );
        } else {
            dispatch(
                updateSelected({
                    edit: false
                })
            );
        }

        handleEditReturn(returnHeader.retNo - 1);
    };
    // Customer change
    const handleCustomer = (e, newValue) => {
        // setSelected({ customer: newValue, contact: newValue.Ph });
        dispatch(
            updateSelected({
                customer: newValue,
                contact: newValue.Ph
            })
        );
    };

    // return no change
    const handleRetNo = (e) => {
        // dispatch(setReturnNo(e.target.value));
        dispatch(
            updateSelected({
                retNo: parseInt(e.target.value)
            })
        );
        if (e.target.value - 1 < returnHeader.retBill) {
            dispatch(
                updateSelected({
                    edit: true
                })
            );
        } else {
            dispatch(
                updateSelected({
                    edit: false
                })
            );
        }

        handleEditReturn(e.target.value);
    };

    const handleEditReturn = (no) => {
        // Make an axios post request to the updateBill endpoint
        axios
            .post(baseUrl + updateBill, { BillNo: no })
            .then((res) => {
                if (res.data[1].length > 0) {
                    const data = res.data[0][0]; // Simplify data access

                    dispatch(replaceTableData(res.data[1]));
                    dispatch(updateSelected({ customer: { Name: data.BillPartyName } }));
                    dispatch(updateSelected({ date: dayjs(data.BillDate) }));
                    dispatch(setDiscPer(data.BillDiscPercField));
                    dispatch(setDiscAmt(data.BillDiscAmtField));
                    dispatch(setTaxAmt(data.TaxAmt));
                    dispatch(setTaxPer(data.BillTaxField));
                    dispatch(setTotal(data.BillTotalField));
                    dispatch(setNetTotal(data.BillNetTotalField));
                    dispatch(updateSelected({ dealer: { Typ: data.Employee } }));
                    dispatch(updateSelected({ outlet: { Name: data.godown } }));
                    dispatch(setOther(data.BillPackageField));
                } else {
                    dispatch(setToDefaultCalc());
                    dispatch(clearTableData());
                }
            })

            .catch((error) => {
                if (error.response && error.response.status === 403) {
                    navigate(loginPath);
                }
            });
    };
    // Outlet change
    const handleOutlet = (e, newValue) => {
        dispatch(
            updateSelected({
                outlet: newValue
            })
        );
    };
    // dealer change
    const handleDealer = (e, newValue) => {
        dispatch(
            updateSelected({
                dealer: newValue
            })
        );
    };

    return (
        <Box sx={{ px: 1 }}>
            <Grid container spacing={1}>
                <Grid item xs={6} sm={4} md={2} lg={2}>
                    <Autocomplete
                        disableClearable
                        id="combo-box-demo"
                        options={headerInfo[0] || []}
                        getOptionLabel={(option) => option.Name}
                        onChange={(e, newValue) => handleCustomer(e, newValue)}
                        renderInput={(params) => <TextField {...params} label="Party Name" size="small" />}
                        value={returnHeader.customer}
                    />
                </Grid>
                {/* <Grid item xs={6} sm={4} md={2} lg={2}>
                    <TextField
                        fullWidth
                        label="Contact"
                        size="small"
                        value={returnHeader.contact ? returnHeader.contact : ''}
                        placeholder="contact not detected"
                        onChange={(e) =>
                            dispatch(
                                updateSelected({
                                    contact: parseInt(e.target.value)
                                })
                            )
                        }
                        InputLabelProps={{ shrink: true }}
                    />
                </Grid> */}
                <Grid item xs={4} sm={3} md={2} lg={2}>
                    <Autocomplete
                        disablePortal
                        id="dealer-autocomplete"
                        options={headerInfo[2] || []}
                        getOptionLabel={(option) => option.Typ}
                        fullWidth
                        onChange={handleDealer}
                        size="small"
                        renderInput={(params) => <TextField {...params} label="Dealer" />}
                        value={returnHeader.dealer}
                    />
                </Grid>
                <Grid item xs={4} sm={3} md={2} lg={2}>
                    <Autocomplete
                        disablePortal
                        id="outlet-autocomplete"
                        options={headerInfo[1] || []}
                        getOptionLabel={(option) => option.Name}
                        fullWidth
                        onChange={handleOutlet}
                        renderInput={(params) => <TextField {...params} label="Outlet" size="small" />}
                        value={returnHeader.outlet}
                    />
                </Grid>
                <Grid item xs={4} sm={2} md={2} lg={2}>
                    <TextField type="number" label="Invoice No" size="small" fullWidth />
                </Grid>
                <Grid item xs={4} sm={3} md={2} lg={2}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <MobileDatePicker
                            sx={{ width: '100%' }}
                            label=" Date"
                            className="datepick"
                            value={returnHeader.date}
                            onChange={(selectedDate) => handleChange(selectedDate)}
                            format="DD/MM/YYYY"
                            textField={(props) => <TextField {...props} fullWidth size="small" />}
                        />
                    </LocalizationProvider>
                </Grid>
                <Grid item>
                    <IconButton onClick={handleDecrement}>
                        <RemoveCircleIcon />
                    </IconButton>
                </Grid>
                <Grid item>
                    <TextField
                        type="number"
                        label="Return No"
                        size="small"
                        value={returnHeader.retNo}
                        onChange={handleRetNo}
                        inputProps={{
                            min: 0,
                            max: returnHeader.retBill,
                            style: { fontWeight: 'bold', textAlign: 'center' },
                            inputMode: 'numeric',
                            pattern: '[0-9]*'
                        }}
                    />
                </Grid>
                <Grid item>
                    <IconButton onClick={handleIncrement}>
                        <AddCircleIcon />
                    </IconButton>
                </Grid>
            </Grid>
        </Box>
    );
}

export default SalesHeader;
